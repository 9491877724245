import React from 'react';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
// import { useTheme } from '@mui/material/styles';
import Image from '../resources/images/bg.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  sidebar: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    '&.MuiPaper-root': {
      backgroundImage: `url(${Image})`,
      backgroundSize: 'cover',
      height: '100vh',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 0, 0, 0),
      backgroundImage: `url(${Image})`,
      backgroundSize: 'cover',
      height: '100vh',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(5),
    width: '100%',
    borderRadius: 10,
    background: '#fff',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <main className={classes.root}>
      <Paper className={classes.paper}>
        <form className={classes.form}>
          {children}
        </form>
      </Paper>
    </main>
  );
};

export default LoginLayout;
